import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get, post} from "../../network/axiosServices";

const initialState = {
    globalLoader: true,
    formLoading: false,
    data: [],
    error: '',
}

export const postForm = createAsyncThunk("contactForm", (params) => {
    return post(params);
});

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        loading: (state) => {
            state.globalLoader = true
        }
    },

    extraReducers: builder => {
        builder.addCase(postForm.pending, (state) => {
            state.formLoading = true
            state.formData = []
            state.error = ''
        })
        builder.addCase(postForm.fulfilled, (state, action) => {
            state.formLoading = false
            state.formData = action.payload
            state.error = ''
        })
        builder.addCase(postForm.rejected, (state, action) => {
            state.formLoading = false
            state.formData = []
            state.error = action.error
        })
    },


})


export default globalSlice.reducer
export const {loaded, loading} = globalSlice.actions
