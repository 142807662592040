import {motion} from 'framer-motion'
import React, {useEffect, useState} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import styled from 'styled-components'
import {PageAnimation} from '../../components/animations/PageAnimation'
import GetInTouch from '../../components/contact/GetInTouch'
import CTAV3 from '../../components/CTA-v3'
import About from '../../components/home/About'
import Banner from '../../components/home/Banner'
import HomeServices from '../../components/home/HomeServices'
import TestimonialSlider from '../../components/TestimonialSlider'
import USP from '../../components/USP'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchHome} from "../../api/redux/home";
import {ApiParam} from "../../api/network/apiParams";

const MyComponent = () => {
    const [offset, setOffset] = useState('90')
    const [winWidth, setWindowWidth] = useState(0)
    const dispatch = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "home",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispatch(fetchHome([api_url, api_params]));
    }, [PageAnimation]);

    const getData = useSelector((state) => state.home);

    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "home-banner");
    const about = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "about");
    const services = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "services");
    const usp = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "usp");
    const testimonial = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "home-testimonial");
    const getInTouch = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "home-get-in-touch");
    const cta = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "cta");

    // get offset for container
    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container').offsetLeft + 30)
        }
    })

    useEffect(() => {
        const updateOffset = () => {
            if (window.innerWidth > 767) {
                const container = document.querySelector('.container')
                if (container) {
                    setOffset(container.offsetLeft + 15)
                }
            }
        }

        // Add an event listener for the DOMContentLoaded event
        document.addEventListener('DOMContentLoaded', updateOffset)

        // Add event listener to update offset on window resize
        window.addEventListener('resize', updateOffset)
        window.addEventListener('load', updateOffset)

        return () => {
            document.removeEventListener('DOMContentLoaded', updateOffset)
            window.removeEventListener('resize', updateOffset)
            window.removeEventListener('load', updateOffset)
        }
    }, [])

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        // Initial window width
        setWindowWidth(window.innerWidth)

        // Add event listener for window resize
        window.addEventListener('resize', handleResize)

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Elite Tinting | Home</title>
                <meta
                    name="description"
                    content="Elite Tinting offers professional window tinting services for automotive, residential, commercial, and marine applications. Contact us to learn how our expert team can enhance your privacy, energy efficiency, and style with high-quality window films."
                />
            </Helmet>

            <motion.div
                key={`1w83`}
                className="page-loader"
                exit="exit"
                animate="anim"
                variants={PageAnimation}
                initial="init"
            ></motion.div>

            <StyledComponent>
                <Banner data={banner}/>
                <About data={about}/>
                <HomeServices data={services}/>
                <USP data={usp} offset={offset}/>
                <GetInTouch data={getInTouch}/>
                <TestimonialSlider data={testimonial}/>
                <CTAV3
                    data={cta}
                    bgImage={cta?.images?.list?.[0]?.full_path}
                    hideBtn
                    title={cta?.section_data?.subtitle}
                    subtitle={cta?.section_data?.short_desc}
                    offset={offset}
                    winWidth={winWidth}
                />
            </StyledComponent>
        </HelmetProvider>
    )
}

const StyledComponent = styled.section``

export default MyComponent
