import { gsap, TimelineLite } from 'gsap'
import { DrawSVGPlugin } from 'gsap/dist/DrawSVGPlugin'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'

const PageTransition = () => {
  const location = useLocation()
  gsap.registerPlugin(DrawSVGPlugin)

  useEffect(() => {
    const tl = new TimelineLite()
    tl.fromTo(
        '.page-change',
        0.6,
        {
          display: 'flex',
          opacity: 1,
        },
        {
          duration: 1.5,
          delay: 1,
          opacity: 0,
          display: 'none',
        },
    )
    // t2.fromTo('.page-change svg path', {
    //     drawSVG: "0%",
    // }, {
    //     duration: 3,
    //     drawSVG: "100%",
    //     ease: 'Cubic.easeOut',
    //     stagger: .02
    // })
  }, [location.pathname]) // only re-run when location.pathname changes

  return (
      <StyledComponent className={'page-change'}>
        <img src="/images/static/logo.png" alt="" />
      </StyledComponent>
  )
}

const StyledComponent = styled.section`
  height: 100vh;
  position: fixed;
  background-color: #3c3c3b;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999999999999999999;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 500px;
  }

  h1 {
    color: #fff;
  }
`

export default PageTransition