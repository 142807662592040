import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import styled from 'styled-components'
import ServiceItem from '../ServiceItem'
import Title from '../Title'

const HomeServices = ({data}) => {
    return (
        <StyledComponent className={'pt-160 pb-160'}>
            <div className="services-area">
                <Container>
                    <Row>
                        <Col sm={6}>
                            <div className="services-area__left">
                                <Title fontSize={'60'} text={data?.section_data?.subtitle}/>
                            </div>
                        </Col>
                    </Row>
                    <Row className={'services-area__wrap'}>
                        {data?.posts?.list?.map((item, index) => {
                            return (
                                <Col className={'mb-30'} key={1} lg={4} md={12}>
                                    <ServiceItem
                                        url={item?.images?.[0]?.full_path}
                                        name={item?.data?.subtitle}
                                        desc={item?.data?.short_desc}
                                        img={item?.images?.[0]?.full_path}
                                        number={index + 1}
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </div>
        </StyledComponent>
    )
}

const StyledComponent = styled.section`
    padding: 200px 0px;
    overflow: hidden;

    h3 {
        line-height: 60px;
    }

    .services-area {
        &__right {
            overflow: hidden;

            h6 {
                overflow: hidden;
            }
        }

        &__wrap {
            padding-top: 60px;
            /* small mobile :320px. */
            @media (max-width: 767px) {
                padding-top: 40px;
            }
        }
    }

    /* Tablet desktop :768px. */
    @media (min-width: 768px) and (max-width: 991px) {
        padding: 60px 0px;
        .services-area {
            //&__left {
            //    padding-bottom: 40px;
            //}

            h2 {
                font-size: 40px;
                line-height: 40px;
            }

            &__wrap {
                display: flex;
                gap: 30px;
                padding-top: 0px;
            }
        }
    }

    /* small mobile :320px. */
    @media (max-width: 767px) {
        padding: 60px 0px;
        .services-area {
            &__wrap {
                display: flex;
                gap 30px;
                padding-top: 0px !important;
            }
        }
    }
`

export default React.memo(HomeServices)
