import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import styled from 'styled-components'
import { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'

const testimonials = [
  {
    name: 'Stanley Taber',
    title: 'Director at Lorem Ipsum',
    text: 'Cras quis nulla commodo, aliquam lectus sed, blandit augue. Cras ullamcorper bibendum bibendum.',
  },
  {
    name: 'Stanley Taber',
    title: 'Director at Lorem Ipsum',
    text: 'Cras quis nulla commodo, aliquam lectus sed, blandit augue. Cras ullamcorper bibendum bibendum.',
  },
  // Add more testimonials as needed
]

const TestimonialSlider = () => {
  return (
    <StyledComponent className="container-fluid text-white testimonial-main">
      <div className="container position-relative">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          modules={[Autoplay, Navigation]}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <TestimonialCard className="mx-auto">
                <Stars>★★★★★</Stars>
                <Quote>{testimonial.text}</Quote>
                <Author>{testimonial.name}</Author>
                <Title>{testimonial.title}</Title>
              </TestimonialCard>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Navigation Buttons */}
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  padding-bottom: 200px;

  @media (max-width: 767px) {
    padding: 60px 0px;
  }
`

const TestimonialCard = styled.div`
  background-color: #3c3c3b;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  text-align: center;
`

const Stars = styled.div`
  font-size: 1.5rem;
  color: #ffc107;
`

const Quote = styled.p`
  font-style: italic;
  margin: 20px 0;
  color: #fff;
`

const Author = styled.h4`
  margin: 10px 0;
  font-weight: bold;
  color: #fff;
`

const Title = styled.p`
  color: #ccc;
`

const ArrowButton = styled.div`
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #ffc107;
  }
`

const PrevButton = styled(ArrowButton)`
  left: 10px;
`

const NextButton = styled(ArrowButton)`
  right: 10px;
`

// Add these components within the container like this

export default TestimonialSlider
