import React from 'react';
import styled from "styled-components";

const FloatingIcon = ({data}) => {
    return (
        <StyledComponent className={"float-icon"}>
            <div className="icon">
                <ul>
                    {
                        data?.instagram && <li><a target={"_blank"}
                                                  href={data?.instagram}
                                                  rel="noreferrer"><img
                            height={23} width={23}
                            src="/images/static/Instagram_icon.png.png" alt=""/></a>
                        </li>
                    }

                    {
                        data?.whatsapp && <li><a target={"_blank"} href={data?.whatsapp}
                                                 rel="noreferrer"><img
                            height={23} width={23} src="/images/static/whatsapp.png" alt=""/></a></li>
                    }

                    {
                        data?.messenger && <li><a target={"_blank"}
                                                  href={data?.messenger}
                                                  rel="noreferrer"><img
                            height={23} width={23}
                            src="/images/static/messenger.png" alt=""/></a>
                        </li>
                    }

                    {
                        data?.tiktok &&
                        <li><a target={"_blank"} href={data?.tiktok}
                               rel="noreferrer"><img height={23} width={23} src="/images/static/tik-tok.svg"
                                                     alt="tiktok"/></a></li>
                    }
                </ul>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .icon {
        position: fixed;
        right: 24px;
        bottom: 75px;
        z-index: 9;

        li {
            a {
                height: 45px;
                width: 45px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;

                img {
                    height: 23px;
                }
            }

            &:nth-of-type(4) {
                margin-bottom: 20px;
            }

            &:nth-of-type(3) {
                margin-bottom: 20px;
            }

            &:nth-of-type(2) {
                margin-bottom: 20px;
            }

            &:nth-of-type(1) {
                margin-bottom: 20px;
            }
        }
    }
`;

export default FloatingIcon;
