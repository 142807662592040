// pages/disclaimer.js
import React from 'react'
import {Card, Col, Container, Row} from 'react-bootstrap'
import styled from 'styled-components'
import {text} from '../../styles/globalStyleVars'
import ReactHtmlParser from "react-html-parser";

const DisclaimerText = ({data}) => {
    console.log(data)
    return (
        <StyleDisclaimerText>
            <Container className="mt-5">
                <Row className="justify-content-center">
                    <Col md={10} lg={8}>
                        <Card className="p-4 shadow-sm border-0">
                            <Card.Body>
                                {data?.section_data?.subtitle && <h1>{data?.section_data?.subtitle}</h1>}
                                {ReactHtmlParser(data?.section_data?.description)}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </StyleDisclaimerText>
    )
}

const StyleDisclaimerText = styled.section`
    h1 {
        font-size: 40px;
        margin-bottom: 20px;
        text-align: center;
    }

    h5 {
        color: ${text}; /* Use the hover color for headings */
        margin-bottom: 30px;
        margin-top: 30px;
        font-weight: bold;
    }

    ul {
        list-style-type: disc;
        padding-left: 20px;
    }

    ul li {
        margin-bottom: 0.5rem;
    }

    a {
        color: #00a651; /* Match the company’s theme */
    }

    a:hover {
        text-decoration: underline;
    }

    @media (max-width: 767px) {
        h1 {
            font-size: 28px;
            text-align: left;
        }
    }

`

export default DisclaimerText
