import {CSSPlugin, gsap, TimelineLite} from 'gsap'
import React, {useEffect, useState} from 'react'
import {Accordion, Col, Container, Row} from 'react-bootstrap'
import {Link, useLocation} from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
import {hover, red} from '../styles/globalStyleVars'

const MyComponent = ({data}) => {
    gsap.registerPlugin(CSSPlugin)
    const location = useLocation()
    const [windowWidth, setWindowWidth] = useState(0)

    // menu fixed on scroll
    useEffect(() => {
        if (document.body.classList.contains('scroll-down')) {
            document.body.classList.remove('scroll-down')
        }
    })

    useEffect(() => {
        const body = document.body
        const scrollUp = 'scroll-up'
        const scrollDown = 'scroll-down'
        let lastScroll = 0
        let howMuchScroll

        if (window.screen.width < 991) {
            howMuchScroll = 150
        } else {
            howMuchScroll = 150
        }

        window.addEventListener('scroll', () => {
            let currentScroll = window.pageYOffset

            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp)
                body.classList.remove(scrollDown)
                return
            }

            if (currentScroll > lastScroll && currentScroll > howMuchScroll) {
                // down
                body.classList.remove(scrollUp)
                body.classList.add(scrollDown)
            } else if (currentScroll < lastScroll && currentScroll > howMuchScroll) {
                // up
                body.classList.remove(scrollDown)
                body.classList.add(scrollUp)
            }

            lastScroll = currentScroll
        })
    }, [])

    // menu action
    useEffect(() => {
        const tl = new TimelineLite()

        document.querySelector('.hamburger').addEventListener('click', function () {
            if (document.body.classList.contains('menu-open')) {
                document.body.classList.remove('menu-open')
                tl.to('.main-menu__slide', 0.5, {
                    x: '100%',
                    alpha: 0,
                }).to('.main-menu__slide', 0.2, {
                    display: 'none',
                })
            } else {
                document.body.classList.add('menu-open')
                tl.to('.main-menu__slide', 0.2, {
                    display: 'block',
                }).to('.main-menu__slide', 0.5, {
                    x: 0,
                    alpha: 1,
                })
            }
        })

        const getAllA = document.querySelectorAll('a')
        getAllA.forEach(e => {
            e.addEventListener('click', function () {
                document.body.classList.remove('menu-open')
                tl.to('.main-menu__slide', 0.5, {
                    x: '100%',
                    alpha: 0,
                }).to('.main-menu__slide', 0.2, {
                    display: 'none',
                })
            })
        })
    }, [])

    useEffect(() => {
        const getAlla = document.querySelectorAll('.main-menu__top')
        const getAllul = document.querySelectorAll('.main-menu__top li ul')
        getAlla.forEach(e => {
            const tl = new TimelineLite()

            e.addEventListener('click', function () {
                gsap.to('.main-menu__top li ul', {
                    display: 'none',
                    duration: 0,
                })

                setTimeout(() => {
                    getAllul.forEach(e => {
                        e.removeAttribute('style')
                    })
                }, 600)
            })
        })
    }, [])

    useEffect(() => {
        // Update the window width once the component mounts
        const handleResize = () => setWindowWidth(window.innerWidth)

        // Set initial width
        setWindowWidth(window.innerWidth)

        // Add event listener for window resize
        window.addEventListener('resize', handleResize)

        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty dependency array ensures this runs once on mount

    return (
        <StyledComponent className={`main-menu`}>
            <Container>
                <Row>
                    <Col className={'main-menu__logo'}>
                        <Link to={'/'}>
                            <img src="/images/static/logo.png" alt="logo"/>
                        </Link>
                    </Col>

                    <Col className={'d-flex align-items-center justify-content-end'}>
                        <ul className={'main-menu__top'}>
                            <li className={location.pathname === '/' ? 'active' : ''}>
                                <Link to={'/'}>Home</Link>
                            </li>
                            <li
                                className={
                                    location.pathname === '/elite-tinting' ? 'active' : ''
                                }
                            >
                                <Link to={'/elite-tinting'}> Elite Tinting </Link>
                                <ul>
                                    <li>
                                        <Link to={'/elite-tinting#automotive'}>Automotive</Link>
                                    </li>
                                    <li>
                                        <Link to={'/elite-tinting#residential-commercial'}>
                                            Residential and Commercial
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/elite-tinting#marine'}>Marine</Link>
                                    </li>
                                </ul>
                            </li>
                            <li
                                className={
                                    location.pathname === '/auto-accessories' ? 'active' : ''
                                }
                            >
                                <Link to={'/auto-accessories'}>Auto Accessories</Link>
                            </li>
                            <li className={location.pathname === '/gallery' ? 'active' : ''}>
                                <Link to={'/gallery'}>Gallery</Link>
                            </li>
                            <li className={location.pathname === '/pricing' ? 'active' : ''}>
                                <Link to={'/pricing'}>Pricing</Link>
                            </li>
                            <li
                                className={location.pathname === '/disclaimer' ? 'active' : ''}
                            >
                                <Link to={'/disclaimer'}>Disclaimer</Link>
                            </li>
                            <li className={location.pathname === '/contact' ? 'active' : ''}>
                                <Link to={'/contact'}>Contact</Link>
                            </li>
                            <li className={'hamburger'}>
                                <img
                                    loading={'lazy'}
                                    src="/images/static/hamburger.svg"
                                    alt=""
                                />
                                <img loading={'lazy'} src="/images/static/close.svg" alt=""/>
                            </li>
                        </ul>
                        {windowWidth > 991 && data?.office_phone && (
                            <li className="call d-sm-none d-md-block  hover-red show-in-desktop">
                                <a href={`tel:+${data?.office_phone}`}>
                                    <svg
                                        id="Button_-_Hotline_-_Light"
                                        data-name="Button - Hotline - Light"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="170"
                                        height="44"
                                        viewBox="0 0 170 44"
                                    >
                                        <g
                                            id="Rectangle_492"
                                            data-name="Rectangle 492"
                                            fill="none"
                                            stroke="#fff"
                                            stroke-width="1"
                                            opacity="0.3"
                                        >
                                            <rect width="120" height="44" rx="22" stroke="none"/>
                                            <rect
                                                x="0.5"
                                                y="0.5"
                                                width="169"
                                                height="43"
                                                rx="21.5"
                                                fill="none"
                                            />
                                        </g>
                                        <g
                                            id="Rectangle_493"
                                            data-name="Rectangle 493"
                                            fill="none"
                                            stroke="#fff"
                                            stroke-width="2"
                                            strokeDasharray="1 520"
                                            opacity="0"
                                        >
                                            <rect width="120" height="44" rx="22" stroke="none"/>
                                            <rect
                                                x="0.5"
                                                y="0.5"
                                                width="169"
                                                height="43"
                                                rx="21.5"
                                                fill="none"
                                            />
                                        </g>
                                        <g
                                            id="Group_4811"
                                            data-name="Group 4811"
                                            transform="translate(-791 -27)"
                                        >
                                            <text
                                                id="_16634"
                                                data-name="16634"
                                                transform="translate(843 54)"
                                                fill="#fff"
                                                font-size="16"
                                                font-family="Avenir-Medium, Avenir"
                                                font-weight="500"
                                            >
                                                <tspan x="0" y="0">
                                                    {data?.office_phone}                                                </tspan>
                                            </text>
                                            <path
                                                id="Path_8647"
                                                data-name="Path 8647"
                                                d="M4.965,13.295a21.287,21.287,0,0,0,7.084,5.528,11.057,11.057,0,0,0,3.964,1.169c.1,0,.187.008.283.008a3.289,3.289,0,0,0,2.546-1.09.09.09,0,0,0,.017-.021,10.7,10.7,0,0,1,.8-.829c.2-.186.4-.381.587-.58a2,2,0,0,0-.008-2.98l-2.5-2.491a2.027,2.027,0,0,0-1.464-.671,2.1,2.1,0,0,0-1.481.667l-1.489,1.484c-.137-.079-.279-.149-.412-.215a5.15,5.15,0,0,1-.458-.249A15.74,15.74,0,0,1,8.671,9.61,9.037,9.037,0,0,1,7.4,7.588c.391-.352.757-.721,1.111-1.082.125-.128.254-.257.383-.385a2.121,2.121,0,0,0,.691-1.492,2.1,2.1,0,0,0-.691-1.492L7.652,1.9c-.146-.145-.283-.286-.424-.431C6.953,1.189,6.666.9,6.383.638A2.078,2.078,0,0,0,4.919,0,2.139,2.139,0,0,0,3.438.642L1.882,2.192A3.179,3.179,0,0,0,.929,4.231a7.644,7.644,0,0,0,.578,3.315A19.273,19.273,0,0,0,4.965,13.295ZM1.944,4.318a2.189,2.189,0,0,1,.661-1.409L4.153,1.368a1.122,1.122,0,0,1,.765-.352,1.065,1.065,0,0,1,.749.361c.279.257.541.526.824.812.141.145.287.29.433.439l1.24,1.235a1.124,1.124,0,0,1,.391.775,1.124,1.124,0,0,1-.391.775c-.129.128-.258.261-.387.39-.387.39-.749.758-1.148,1.111l-.021.021a.808.808,0,0,0-.208.92c0,.012.008.021.012.033a9.747,9.747,0,0,0,1.46,2.366,16.613,16.613,0,0,0,4.01,3.639,5.961,5.961,0,0,0,.549.3,5.15,5.15,0,0,1,.458.249l.046.025a.872.872,0,0,0,.4.1.884.884,0,0,0,.62-.282l1.556-1.55a1.1,1.1,0,0,1,.761-.369,1.046,1.046,0,0,1,.736.369L19.52,15.23a1,1,0,0,1-.012,1.562c-.175.186-.358.365-.553.551a11.955,11.955,0,0,0-.869.9A2.288,2.288,0,0,1,16.3,19c-.071,0-.146,0-.216-.008A14.208,14.208,0,0,1,12.5,17.92S7.639,14.9,5.755,12.656a18.448,18.448,0,0,1-3.286-5.45A6.662,6.662,0,0,1,1.944,4.318Z"
                                                transform="translate(814.089 39)"
                                                fill="#fff"
                                            />
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        )}
                    </Col>
                </Row>

                {/*slide item*/}
                <div className="main-menu__slide">
                    <ul>
                        <Accordion>
                            <li>
                                <Link to={'/'}>Home</Link>
                            </li>
                            {/*<li className={location.pathname === '/about' ? 'active' : ''}></li>*/}
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <span>Elite Tinting</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>
                                            <Link to={'/elite-tinting#automotive'}>Automotive</Link>
                                        </li>
                                        <li>
                                            <Link to={'/elite-tinting#residential-commercial'}>
                                                Residential and Commercial
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/elite-tinting#marine'}>Marine</Link>
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>

                            <li>
                                <Link to={'/auto-accessories'}>Auto Accessories</Link>
                            </li>
                            <li>
                                <Link to={'/gallery'}>Gallery</Link>
                            </li>
                            <li>
                                <Link to={'/pricing'}>Pricing</Link>
                            </li>
                            <li>
                                <Link to={'/disclaimer'}>Disclaimer</Link>
                            </li>
                            <li>
                                <Link to={'/contact'}>Contact</Link>
                            </li>
                        </Accordion>
                    </ul>
                </div>
            </Container>
        </StyledComponent>
    )
}

const StyledComponent = styled.section`
    position: fixed;
    z-index: 99;
    width: 100%;
    top: 0;
    height: 120px;

    display: flex;
    align-items: center;
    transition: 0.6s ease;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

    .call {
        a {
            z-index: 2;
            position: relative;
        }
    }

    &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        background-image: url('/images/static/mission-bg.png');
        opacity: 0;
        transition: 0.6s ease;
    }

    h1 {
        color: white;

        @media (max-width: 991px) {
            font-size: 25px;
        }
    }

    &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.23);
        opacity: 0;
        transition: 0.6s ease;
    }

    .main-menu__logo {
        a {
            display: flex;
            height: 100%;
            align-items: center;
        }
    }

    .container {
        height: 100%;
    }

    .row {
        position: relative;
        z-index: 5;
        height: 100%;
    }

    img {
        transition: 0.5s ease;
        height: 115px;
    }

    ul.main-menu__top {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: flex-end;

        li {
            position: relative;
            height: 100%;

            span {
                cursor: pointer;
            }

            a {
                font-size: 14px;
                line-height: 20px;
                color: #ffffff;
                white-space: nowrap;
                height: 100%;
                display: flex;
                align-items: center;
            }

            &:not(:nth-last-of-type(1)) {
                margin-right: 30px;
            }

            &.active > a {
                color: ${red};
                pointer-events: none;
            }

            //sub menu

            ul {
                position: absolute;
                width: 250px;
                background-color: #fff;
                padding: 30px 20px 15px 20px;
                top: 100px;
                opacity: 0;
                visibility: hidden;
                transition: 0.5s ease;
                box-shadow: 0 0 20px rgba(221, 221, 221, 0.29);

                li {
                    a {
                        color: rgb(29, 49, 48);
                        width: 100%;
                    }

                    margin-bottom: 15px;
                    //&:not(:nth-last-of-type(1)) {
                    //  margin-bottom: 10px;
                    //}
                }
            }

            &.hamburger {
                height: 48px;
                width: 48px;
                border-radius: 50%;
                border: 1px solid #fff;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                display: none;

                img {
                    height: 15px;

                    &:nth-last-of-type(1) {
                        display: none;
                    }
                }
            }

            &:hover {
                ul {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .main-menu__slide {
        position: fixed;
        height: calc(100vh - 80px);
        top: 80px;
        width: 100%;
        bottom: 0;
        right: 0;
        background-color: #555555;
        padding-top: 60px;
        overflow: auto;
        padding-bottom: 50px;
        display: none;
        transform: translateX(100%);
        opacity: 0;
        z-index: 999;

        li {
            a {
                color: #ffffff;
                font-size: 18px;
                line-height: 27px;
                width: 100%;
                display: flex;
                justify-content: center;
            }

            &:not(:nth-last-of-type(1)) {
                margin-bottom: 40px;
            }

            &.active {
                a {
                    color: ${hover};
                }

                ul li a {
                    color: #fff;
                }
            }
        }
    }

    .accordion-item {
        width: 100%;
        margin-bottom: 40px;

        h2 {
            line-height: unset;
        }

        .accordion-button {
            background-color: transparent;
            width: 100%;
            text-align: center;
            border: none;
            outline: none;
            box-shadow: none;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
        }

        .accordion-body {
            padding-top: 30px;
            //padding-bottom: 30px;

            ul {
                li {
                    &:not(:nth-last-of-type(1)) {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        &.active {
            button {
                color: ${hover};
            }
        }
    }

    @media (max-width: 991px) {
        height: 80px;
        padding-top: 0px;
        img:not(ul img) {
            height: 60px;
        }

        ul.main-menu__top li {
            &:not(:nth-last-of-type(1)) {
                display: none;
            }

            &:nth-last-of-type(1) {
                display: flex;
            }
        }
    }
`

export default MyComponent
