import React from 'react'
import {Container} from 'react-bootstrap'
import styled from 'styled-components'
import Title from '../Title'
import ReactHtmlParser from "react-html-parser";

const Note = ({title, desc}) => {
    return (
        <StyledComponent>
            <Container className={'text-center'}>
                <Title margin={'0 auto'} text={title}/>
                <p>{ReactHtmlParser(desc)}</p>
            </Container>
        </StyledComponent>
    )
}

const StyledComponent = styled.section`
    padding: 60px 0;

    p {
        padding-top: 30px;
    }
`

export default Note
