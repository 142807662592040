import {motion} from 'framer-motion'
import React, {useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {apiEndPoints} from '../../api/network/apiEndPoints'
import {ApiParam} from '../../api/network/apiParams'
import {PageAnimation} from '../../components/animations/PageAnimation'
import GetInTouch from '../../components/contact/GetInTouch'
import Info from '../../components/contact/Info'
import Note from '../../components/contact/Note'
import InnerBanner from '../../components/InnerBanner'
import {fetchHome} from "../../api/redux/home";
import {fetchContact} from "../../api/redux/contact";
import {fetchDisclaimer} from "../../api/redux/disclaimer";

const Contact = () => {
    const dispatch = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: 'contact',
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
        }

        dispatch(fetchContact([api_url, api_params]))
    }, [])

    const getData = useSelector((state) => state.contact);

    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "contact-banner");
    const infoData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "info");
    const contactFormData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "contact-form");
    const noteData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "contact-note");

    console.log(noteData?.section_data?.description)
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Contact | Elite Tinting</title>
                <meta
                    name="description"
                    content="Get in touch with Elite Tinting for professional window tinting services for your car, home, business, or boat. Our experts are ready to assist you with all your tinting needs, ensuring quality and satisfaction."
                />
            </Helmet>

            <motion.div
                key={`1w83`}
                className="page-loader"
                exit="exit"
                animate="anim"
                variants={PageAnimation}
                initial="init"
            ></motion.div>

            <StyledComponent>
                <InnerBanner
                    data={banner}
                    img={banner?.images?.list?.[0]?.full_path}
                    srcSm={banner?.images?.list?.[0]?.full_path}
                />
                <Info data={infoData} />
                <GetInTouch data={contactFormData} />
                <Note
                    title={'Note'}
                    desc={
                        noteData?.section_data?.description
                    }
                />
            </StyledComponent>
        </HelmetProvider>
    )
}

const StyledComponent = styled.section``

export default Contact
