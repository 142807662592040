import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components'
import { Img } from './Img'

const WhoWeAre = ({ title, desc, img, id }) => {
  return (
    <StyledComponent id={id}>
      <Container>
        <Row>
          <Col lg={6} md={12}>
            <div className="text-mb">
              <h1>{title}</h1>
            </div>
            <div className="image">
              <Img src={img} />
            </div>
          </Col>
          <Col lg={{ offset: 1, span: 4 }} md={12}>
            <div className="text">
              <h1>{title}</h1>
              <p>{ReactHtmlParser(desc)}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  padding: 120px 0px;
  background: #f9f9f9;

  .text-mb {
    display: none;
  }

  .image {
    position: relative;
    padding-top: calc(390 / 585 * 100%);
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    h1 {
      margin-bottom: 40px;
    }

    p {
      text-align: justify;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .text {
      margin-top: 30px;

      h1 {
        margin-bottom: 0px;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 80px 0;
    .text {
      margin-top: 0px;
      padding-top: 0px;

      h1 {
        display: none;
      }

      p {
        padding-top: 40px;
      }
    }

    .text-mb {
      display: block;
      margin-bottom: 40px;

      h1 {
        font-size: 30px;
        font-weight: 400;
        line-height: 40px;
      }
    }

    .image {
      position: relative;
      padding-top: calc(230 / 345 * 100%);
    }
  }
`

export default WhoWeAre
