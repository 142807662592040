import React, {useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import styled from 'styled-components'
import AccessoriesGallery from '../../components/accessories/AccessoriesGallery'
import GetInTouch from '../../components/contact/GetInTouch'
import Note from '../../components/contact/Note'
import CTAV3 from '../../components/CTA-v3'
import InnerBanner from '../../components/InnerBanner'
import {fetchAccessories} from "../../api/redux/autoAccessories";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from '../../api/network/apiParams'

const AutoAccessories = () => {
    const dispatch = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: 'auto-accessories',
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
        }

        dispatch(fetchAccessories([api_url, api_params]))

    }, [])

    const getData = useSelector((state) => state.autoAccessories);

    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "auto-accessories-banner");
    const bestSeller = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "best-seller");
    const ctaData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "auto-accessries-cta");
    const formData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "auto-accessories-form");
    const noteData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "auto-accessories-note");

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Auto Accessories | Elite Tint</title>
                <meta
                    name="description"
                    content="Explore Elite Tint's premium auto accessories, including window tinting, paint protection, and more. Enhance your vehicle's style, comfort, and performance with our top-quality products and services."
                />
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    data={banner}
                    img={banner?.images?.list?.[0]?.full_path}
                    srcSm={banner?.images?.list?.[0]?.full_path}
                />
                <AccessoriesGallery data={bestSeller} />
                <CTAV3
                    hideCard
                    bgImage={ctaData?.images?.list?.[0]?.full_path}
                    title={
                        ctaData?.section_data?.subtitle
                    }
                    call={ctaData?.section_data?.short_desc}
                />
                <GetInTouch data={formData} />
                <Note
                    title={'Note'}
                    desc={
                        noteData?.section_data?.description
                    }
                />
            </StyledComponent>
        </HelmetProvider>
    )
}

const StyledComponent = styled.section``

export default AutoAccessories
