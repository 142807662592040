import React from 'react'
import styled from 'styled-components'
import { Img } from './Img'

const MyComponent = ({ number, desc, name, url, img }) => {
  return (
    <StyledComponent>
      <div className="blog-single">
        <div className="blog-single__inner">
          {/*<Link href={'#'}><a/></Link>*/}
          <Img
            shadow
            src={img}
            objectFit={'cover'}
            layout={'fill'}
            name={name}
            alt={name}
          />
          <div className="blog-single__inner__content">
            <div className="blog-single__inner__content__top">
              <div className="upper">
                <p className={'split-up'}>{desc}</p>
              </div>

              <h2 className={'split-up'}>{number}</h2>
            </div>

            <div className="blog-single__inner__content__bottom">
              <h5 className={'split-up'}>{name}</h5>
            </div>
          </div>
        </div>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  .blog-single {
    .blog-single__inner {
      padding-top: calc(270 / 370 * 100%);
      position: relative;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 4;
      }

      &__content {
        &:after {
          content: '';
          height: 0%;
          width: 100%;
          background-color: #3c3c3b; /* This color will be multiplied with the background */
          bottom: 0;
          left: 0;
          right: 0;
          position: absolute;
          transition: height 0.4s ease;
          mix-blend-mode: multiply; /* Use mix-blend-mode instead of background-blend-mode */
        }

        &__top {
          .upper {
            position: absolute;
            left: 30px;
            top: 40px;
            z-index: 2;
            right: 30px;
            margin: 0;
            transform: translateY(-30px);
            opacity: 0;
            transition: all 0.6s ease;
            overflow: hidden;
          }

          p {
            color: #ffffff;
            margin: 0 0 40px 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }

          .read-more {
            font-size: 20px;
            line-height: 24px;
            font-weight: 300;
            color: #ffffff;
            margin: 0;
            text-decoration: underline;
          }

          h2 {
            position: absolute;
            top: 40px;
            left: 40px;
            right: 40px;
            z-index: 2;
            font-size: 72px;
            font-weight: 500;
            line-height: 80px;
            color: #ffffff;
            margin: 0;
            transition: all 0.3s ease;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        &__bottom {
          position: absolute;
          margin: 0;
          left: 40px;
          right: 40px;
          bottom: 20px;
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          z-index: 3;

          h3 {
            color: #ffffff;
            transition: color 0.3s ease;

            p {
              display: block;
              color: #ffffff;
            }
          }

          h5 {
            color: #ffffff;
            overflow: hidden;
            bottom: 20px;
            font-weight: bold;
          }

          p {
            position: relative;
            top: 40px;
            left: 40px;
            font-size: 16px;
            color: #ffffff;
            text-align: right;
            line-height: 20px;
            transition: color 0.3s ease;

            span {
              display: block;
              color: #ffffff;
            }
          }
        }
      }

      &:hover {
        .blog-single__inner__content__top {
          h2 {
            opacity: 0;
            transform: translateY(-20px);
          }

          .upper {
            transform: none;
            opacity: 1;
          }
        }

        .blog-single__inner__content__bottom {
          border-color: #fff;

          h4 {
            color: #ffffff;
          }

          p {
            color: #ffffff;

            span {
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  &:hover {
    .blog-single__inner__content:after {
      height: 100%;
    }

    .navigation {
      li {
        &.hover {
          border-color: white !important;

          &.hover:after {
            background-color: #fff !important;
            top: 0px;
          }

          svg {
            position: relative;

            line {
              stroke: #ca0034 !important;
            }
          }
        }
      }
    }
  }
`

export default React.memo(MyComponent)
