import {motion} from 'framer-motion'
import React, {useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {apiEndPoints} from '../../api/network/apiEndPoints'
import {ApiParam} from '../../api/network/apiParams'
import {PageAnimation} from '../../components/animations/PageAnimation'
import Note from '../../components/contact/Note'
import DisclaimerText from '../../components/DisclaimerText/DisclaimerText'
import InnerBanner from '../../components/InnerBanner'
import {fetchHome} from "../../api/redux/home";
import {fetchDisclaimer} from "../../api/redux/disclaimer";

const Disclaimer = ({data}) => {
    const dispatch = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: 'disclaimer',
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
        }

        dispatch(fetchDisclaimer([api_url, api_params]))
    }, [])

    const getData = useSelector((state) => state.disclaimer);

    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "disclaimer-banner");
    const disclaimerTextData  = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "disclaimer-text");
    const noteData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "auto-accessories-note");

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Disclaimer | Elite Tint</title>
                <meta
                    name="description"
                    content="Read the disclaimer for Elite Tint, outlining the terms and conditions regarding the information provided on our website, services, and warranties for window tinting installations."
                />
            </Helmet>

            <motion.div
                key={`1w83`}
                className="page-loader"
                exit="exit"
                animate="anim"
                variants={PageAnimation}
                initial="init"
            ></motion.div>

            <StyledComponent>
                <InnerBanner
                    data={banner}
                    img={banner?.images?.list?.[0]?.full_path}
                    srcSm={banner?.images?.list?.[0]?.full_path}
                />
                <DisclaimerText data={disclaimerTextData} />
                <Note
                    title={'Note'}
                    desc={
                        noteData?.section_data?.description
                    }
                />
            </StyledComponent>
        </HelmetProvider>
    )
}

const StyledComponent = styled.section``

export default Disclaimer
