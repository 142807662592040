import {gsap} from 'gsap'
import React, {useEffect} from 'react'
import {Accordion, Col, Container, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data, hour}) => {
    // Return today's date and time
    var currentTime = new Date()

    // returns the year (four digits)
    var year = currentTime.getFullYear()

    useEffect(() => {
        // Add click event to all links
        const allLinks = document.querySelectorAll('a[to*="#"]')
        if (!allLinks.length) {
            return
        }

        allLinks.forEach(link => {
            link.addEventListener('click', function (event) {
                // event.preventDefault(); // Prevent the default link behavior (navigating to the to)

                const to = link.getAttribute('to')
                if (!to || !to.includes('#')) {
                    return
                }

                const targetSelector = to.slice(to.indexOf('#')) // Extract the # value from the to
                const targetElement = document.querySelector(targetSelector)

                if (targetElement) {
                    setTimeout(() => {
                        // Revert body overflow to its original value on blur
                        document.body.style.overflow = 'auto'
                        const html = document.querySelector('html')
                        html.style.overflow = 'auto'

                        // Use GSAP ScrollTo plugin to smoothly scroll to the target section
                        gsap.to(window, {
                            duration: 1,
                            scrollTo: {y: targetElement.offsetTop - 100}, // Adjust the offset as needed
                        })
                    }, 600)
                }
            })
        })
    }, [currentTime])

    return (
        <>
            <Footer className={'footer '}>
                <Accordion defaultActiveKey="0">
                    <div className="footer__top">
                        <Container>
                            <Row>
                                <Col md={4}>
                                    <div className="footer__top__info">
                                        {data?.office_phone && (
                                            <p className="phone">
                                                <span className="d-block">Call</span>
                                                <a href={`tel:${data.office_phone}`}>{data.office_phone}</a>
                                            </p>
                                        )}

                                        <p className="footer-address">
                                            <span className="d-block">Visit</span>
                                            <a
                                                target="_blank"
                                                href={data?.office_location_url}
                                                rel="noreferrer"
                                            >
                                                {data?.office_location}
                                            </a>
                                        </p>


                                        <div className="hide-mobile">
                                            <ul className="social">
                                                {
                                                    data?.facebook && <li>
                                                        <a
                                                            href={data?.facebook}
                                                            target={'_blank'}
                                                            rel="noreferrer"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="30"
                                                                height="30"
                                                                viewBox="0 0 30 30"
                                                            >
                                                                <g
                                                                    id="Group_19435"
                                                                    data-name="Group 19435"
                                                                    transform="translate(15536 -9208)"
                                                                >
                                                                    <circle
                                                                        id="Ellipse_602"
                                                                        data-name="Ellipse 602"
                                                                        cx="15"
                                                                        cy="15"
                                                                        r="14"
                                                                        transform="translate(-15536 9208)"
                                                                        fill="none"
                                                                        stroke="#fff"
                                                                        strokeWidth="1"
                                                                    />
                                                                    <circle
                                                                        id="Ellipse_603"
                                                                        data-name="Ellipse 603"
                                                                        cx="15"
                                                                        cy="50"
                                                                        r="15"
                                                                        transform="translate(-15536 9208)"
                                                                        fill="#978c21"
                                                                    />
                                                                    <path
                                                                        id="Path_2115"
                                                                        data-name="Path 2115"
                                                                        d="M1206.12,104.34l.406-2.652h-2.544v-1.72a1.325,1.325,0,0,1,1.495-1.432h1.157V96.278a14.1,14.1,0,0,0-2.053-.179,3.237,3.237,0,0,0-3.465,3.569v2.021h-2.329v2.652h2.329v6.409h2.866V104.34Z"
                                                                        transform="translate(-16723.787 9119.901)"
                                                                        fill="#fff"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </a>
                                                    </li>
                                                }

                                                {
                                                    data?.instagram && <li>
                                                        <a
                                                            href={data?.instagram}
                                                            target={'_blank'}
                                                            rel="noreferrer"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="30"
                                                                height="30"
                                                                viewBox="0 0 30 30"
                                                            >
                                                                <g
                                                                    id="Group_19435"
                                                                    data-name="Group 19435"
                                                                    transform="translate(15536 -9208)"
                                                                >
                                                                    <circle
                                                                        id="Ellipse_602"
                                                                        data-name="Ellipse 602"
                                                                        cx="15"
                                                                        cy="15"
                                                                        r="14"
                                                                        transform="translate(-15536 9208)"
                                                                        fill="none"
                                                                        stroke="#fff"
                                                                        strokeWidth="1"
                                                                    />
                                                                    <circle
                                                                        id="Ellipse_603"
                                                                        data-name="Ellipse 603"
                                                                        cx="15"
                                                                        cy="50"
                                                                        r="15"
                                                                        transform="translate(-15536 9208)"
                                                                        fill="#978c21"
                                                                    />
                                                                </g>
                                                                <g
                                                                    id="Group_1419"
                                                                    data-name="Group 1419"
                                                                    transform="translate(8 8)"
                                                                >
                                                                    <path
                                                                        id="Path_2109"
                                                                        data-name="Path 2109"
                                                                        d="M1095.77,105.945a.854.854,0,1,0,.853.854A.854.854,0,0,0,1095.77,105.945Z"
                                                                        transform="translate(-1084.635 -103.346)"
                                                                        fill="#fff"
                                                                    />
                                                                    <path
                                                                        id="Path_2110"
                                                                        data-name="Path 2110"
                                                                        d="M1082.64,108.605a3.586,3.586,0,1,0,3.586,3.586A3.59,3.59,0,0,0,1082.64,108.605Zm0,5.882a2.3,2.3,0,1,1,2.3-2.3A2.3,2.3,0,0,1,1082.64,114.488Z"
                                                                        transform="translate(-1075.301 -104.911)"
                                                                        fill="#fff"
                                                                    />
                                                                    <path
                                                                        id="Path_2111"
                                                                        data-name="Path 2111"
                                                                        d="M1080.119,114.188h-5.813a4.379,4.379,0,0,1-4.374-4.374V104a4.378,4.378,0,0,1,4.374-4.373h5.813a4.378,4.378,0,0,1,4.374,4.373v5.813A4.379,4.379,0,0,1,1080.119,114.188ZM1074.306,101a3.007,3.007,0,0,0-3,3v5.813a3.007,3.007,0,0,0,3,3h5.813a3.007,3.007,0,0,0,3-3V104a3.007,3.007,0,0,0-3-3Z"
                                                                        transform="translate(-1069.932 -99.628)"
                                                                        fill="#fff"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </a>
                                                    </li>
                                                }
                                                {
                                                    data?.tiktok && <li>
                                                        <a
                                                            href={data?.tiktok}
                                                            target={'_blank'}
                                                            rel="noreferrer"
                                                        >
                                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z"
                                                                    stroke="white"/>
                                                                <path
                                                                    d="M20.3671 12.0455C19.7369 11.9067 19.1662 11.5641 18.7383 11.0679C18.3103 10.5716 18.0475 9.94752 17.9881 9.28661V9H15.8142V17.8856C15.8128 18.279 15.6918 18.6621 15.4682 18.981C15.2446 19.2999 14.9295 19.5386 14.5674 19.6636C14.2052 19.7886 13.8141 19.7936 13.4491 19.6779C13.084 19.5622 12.7634 19.3315 12.5321 19.0184C12.2973 18.7001 12.1671 18.313 12.1603 17.9133C12.1534 17.5135 12.2704 17.122 12.4943 16.7953C12.7181 16.4687 13.0371 16.224 13.4051 16.0967C13.7731 15.9694 14.1708 15.9661 14.5407 16.0873V13.8126C13.7159 13.6952 12.876 13.8462 12.1386 14.2446C11.4012 14.643 10.8031 15.2688 10.4281 16.0345C10.053 16.8002 9.9197 17.6675 10.0468 18.515C10.1739 19.3626 10.555 20.148 11.1369 20.7615C11.6918 21.3463 12.4033 21.7475 13.1806 21.9139C13.9579 22.0803 14.7657 22.0044 15.5009 21.6958C16.2362 21.3873 16.8654 20.8601 17.3084 20.1816C17.7514 19.503 17.988 18.7039 17.9881 17.8863V13.346C18.8666 13.9929 19.92 14.3397 21 14.3378V12.1131C20.7873 12.1134 20.5752 12.0907 20.3671 12.0455Z"
                                                                    fill="white"/>
                                                            </svg>


                                                        </a>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2} className={'footer__top_col border'}>
                                    <div className="footer__top__menu">
                                        <div className="hide-desktop">
                                            <p>Our Services</p>
                                            <ul>
                                                <li>
                                                    <Link to={'/elite-tinting#automotive'}>AUTOMOTIVE</Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={'/elite-tinting#residential-commercial'}>RESIDENTIAL</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/elite-tinting#residential-commercial'}>COMMERCIAL</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/elite-tinting#marine'}>
                                                        MARINE
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="hide-mobile">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    Our Services
                                                    <span>
                            <svg
                                id="Group_19302"
                                data-name="Group 19302"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                              <g
                                  id="Rectangle_492"
                                  data-name="Rectangle 492"
                                  fill="none"
                                  stroke="#fff"
                                  strokeWidth="1"
                                  opacity="0.5"
                              >
                                <rect
                                    width="24"
                                    height="24"
                                    rx="12"
                                    stroke="none"
                                />
                                <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    rx="11.5"
                                    fill="none"
                                />
                              </g>
                              <g
                                  id="Group_4824"
                                  data-name="Group 4824"
                                  transform="translate(7 7)"
                              >
                                <g
                                    id="Group_4823"
                                    data-name="Group 4823"
                                    transform="translate(5)"
                                >
                                  <line
                                      id="Line_9"
                                      data-name="Line 9"
                                      y1="5"
                                      x2="5"
                                      transform="translate(0 5)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeWidth="1"
                                  />
                                  <line
                                      id="Line_10"
                                      data-name="Line 10"
                                      x1="5"
                                      y1="5"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeWidth="1"
                                  />
                                </g>
                                <line
                                    id="Line_11"
                                    data-name="Line 11"
                                    x2="10"
                                    transform="translate(0 5)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="1"
                                />
                              </g>
                            </svg>
                          </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <ul>
                                                        <li>
                                                            <Link to={'/elite-tinting#automotive'}>AUTOMOTIVE</Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                to={'/elite-tinting#residential-commercial'}>RESIDENTIAL</Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                to={'/elite-tinting#residential-commercial'}>COMMERCIAL</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'/elite-tinting#marine'}>
                                                                MARINE
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2} className={'footer__top_col border'}>
                                    <div className="footer__top__menu">
                                        <div className="hide-desktop">
                                            <p>Browse Our Website</p>
                                            <ul>
                                                <li>
                                                    <Link to={'/'}>
                                                        Home
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={'/elite-tinting'}>
                                                        Elite Tinting
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={'/auto-accessories'}>
                                                        Auto Accessories
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={'/gallery'}>
                                                        Gallery
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={'/contact'}>
                                                        Contact
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={'/disclaimer'}>
                                                        Disclaimer
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="hide-mobile">
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    Browse Our Website
                                                    <span>
                            <svg
                                id="Group_19302"
                                data-name="Group 19302"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                              <g
                                  id="Rectangle_492"
                                  data-name="Rectangle 492"
                                  fill="none"
                                  stroke="#fff"
                                  strokeWidth="1"
                                  opacity="0.5"
                              >
                                <rect
                                    width="24"
                                    height="24"
                                    rx="12"
                                    stroke="none"
                                />
                                <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    rx="11.5"
                                    fill="none"
                                />
                              </g>
                              <g
                                  id="Group_4824"
                                  data-name="Group 4824"
                                  transform="translate(7 7)"
                              >
                                <g
                                    id="Group_4823"
                                    data-name="Group 4823"
                                    transform="translate(5)"
                                >
                                  <line
                                      id="Line_9"
                                      data-name="Line 9"
                                      y1="5"
                                      x2="5"
                                      transform="translate(0 5)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeWidth="1"
                                  />
                                  <line
                                      id="Line_10"
                                      data-name="Line 10"
                                      x1="5"
                                      y1="5"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeWidth="1"
                                  />
                                </g>
                                <line
                                    id="Line_11"
                                    data-name="Line 11"
                                    x2="10"
                                    transform="translate(0 5)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="1"
                                />
                              </g>
                            </svg>
                          </span>
                                                </Accordion.Header>
                                                <Accordion.Body>

                                                    <ul>
                                                        <li>
                                                            <Link to={'/'}>
                                                                Home
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'/window-tinting'}>
                                                                Elite Tinting
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'/auto-accessories'}>
                                                                Auto Accessories
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'/gallery'}>
                                                                Gallery
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'/contact'}>
                                                                Contact
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'/disclaimer'}>
                                                                Disclaimer
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2} className={'footer__top_col'}>
                                    <div className="footer__top__menu">
                                        <div className="hide-desktop">
                                            <p>Business Hours:</p>

                                            <dl className="open-hours-data">
                                                {ReactHtmlParser(hour?.section_data?.description)}
                                            </dl>
                                        </div>

                                        <div className="hide-mobile">
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    Business Hours:
                                                    <span>
                            <svg
                                id="Group_19302"
                                data-name="Group 19302"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                              <g
                                  id="Rectangle_492"
                                  data-name="Rectangle 492"
                                  fill="none"
                                  stroke="#fff"
                                  strokeWidth="1"
                                  opacity="0.5"
                              >
                                <rect
                                    width="24"
                                    height="24"
                                    rx="12"
                                    stroke="none"
                                />
                                <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    rx="11.5"
                                    fill="none"
                                />
                              </g>
                              <g
                                  id="Group_4824"
                                  data-name="Group 4824"
                                  transform="translate(7 7)"
                              >
                                <g
                                    id="Group_4823"
                                    data-name="Group 4823"
                                    transform="translate(5)"
                                >
                                  <line
                                      id="Line_9"
                                      data-name="Line 9"
                                      y1="5"
                                      x2="5"
                                      transform="translate(0 5)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeWidth="1"
                                  />
                                  <line
                                      id="Line_10"
                                      data-name="Line 10"
                                      x1="5"
                                      y1="5"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeWidth="1"
                                  />
                                </g>
                                <line
                                    id="Line_11"
                                    data-name="Line 11"
                                    x2="10"
                                    transform="translate(0 5)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="1"
                                />
                              </g>
                            </svg>
                          </span>
                                                </Accordion.Header>
                                                <Accordion.Body>

                                                    <dl className="open-hours-data">
                                                        {ReactHtmlParser(hour?.section_data?.description)}
                                                    </dl>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </div>
                                    </div>
                                </Col>

                                <Col md={2} className={'footer__top_col hide-desktop'}>
                                    <ul className="social hide-desktop">
                                        {
                                            data?.facebook && <li>
                                                <a
                                                    href={data?.facebook}
                                                    target={'_blank'}
                                                    rel="noreferrer"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="30"
                                                        height="30"
                                                        viewBox="0 0 30 30"
                                                    >
                                                        <g
                                                            id="Group_19435"
                                                            data-name="Group 19435"
                                                            transform="translate(15536 -9208)"
                                                        >
                                                            <circle
                                                                id="Ellipse_602"
                                                                data-name="Ellipse 602"
                                                                cx="15"
                                                                cy="15"
                                                                r="14"
                                                                transform="translate(-15536 9208)"
                                                                fill="none"
                                                                stroke="#fff"
                                                                strokeWidth="1"
                                                            />
                                                            <circle
                                                                id="Ellipse_603"
                                                                data-name="Ellipse 603"
                                                                cx="15"
                                                                cy="50"
                                                                r="15"
                                                                transform="translate(-15536 9208)"
                                                                fill="#978c21"
                                                            />
                                                            <path
                                                                id="Path_2115"
                                                                data-name="Path 2115"
                                                                d="M1206.12,104.34l.406-2.652h-2.544v-1.72a1.325,1.325,0,0,1,1.495-1.432h1.157V96.278a14.1,14.1,0,0,0-2.053-.179,3.237,3.237,0,0,0-3.465,3.569v2.021h-2.329v2.652h2.329v6.409h2.866V104.34Z"
                                                                transform="translate(-16723.787 9119.901)"
                                                                fill="#fff"
                                                            />
                                                        </g>
                                                    </svg>
                                                </a>
                                            </li>
                                        }

                                        {
                                            data?.instagram && <li>
                                                <a
                                                    href={data?.instagram}
                                                    target={'_blank'}
                                                    rel="noreferrer"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="30"
                                                        height="30"
                                                        viewBox="0 0 30 30"
                                                    >
                                                        <g
                                                            id="Group_19435"
                                                            data-name="Group 19435"
                                                            transform="translate(15536 -9208)"
                                                        >
                                                            <circle
                                                                id="Ellipse_602"
                                                                data-name="Ellipse 602"
                                                                cx="15"
                                                                cy="15"
                                                                r="14"
                                                                transform="translate(-15536 9208)"
                                                                fill="none"
                                                                stroke="#fff"
                                                                strokeWidth="1"
                                                            />
                                                            <circle
                                                                id="Ellipse_603"
                                                                data-name="Ellipse 603"
                                                                cx="15"
                                                                cy="50"
                                                                r="15"
                                                                transform="translate(-15536 9208)"
                                                                fill="#978c21"
                                                            />
                                                        </g>
                                                        <g
                                                            id="Group_1419"
                                                            data-name="Group 1419"
                                                            transform="translate(8 8)"
                                                        >
                                                            <path
                                                                id="Path_2109"
                                                                data-name="Path 2109"
                                                                d="M1095.77,105.945a.854.854,0,1,0,.853.854A.854.854,0,0,0,1095.77,105.945Z"
                                                                transform="translate(-1084.635 -103.346)"
                                                                fill="#fff"
                                                            />
                                                            <path
                                                                id="Path_2110"
                                                                data-name="Path 2110"
                                                                d="M1082.64,108.605a3.586,3.586,0,1,0,3.586,3.586A3.59,3.59,0,0,0,1082.64,108.605Zm0,5.882a2.3,2.3,0,1,1,2.3-2.3A2.3,2.3,0,0,1,1082.64,114.488Z"
                                                                transform="translate(-1075.301 -104.911)"
                                                                fill="#fff"
                                                            />
                                                            <path
                                                                id="Path_2111"
                                                                data-name="Path 2111"
                                                                d="M1080.119,114.188h-5.813a4.379,4.379,0,0,1-4.374-4.374V104a4.378,4.378,0,0,1,4.374-4.373h5.813a4.378,4.378,0,0,1,4.374,4.373v5.813A4.379,4.379,0,0,1,1080.119,114.188ZM1074.306,101a3.007,3.007,0,0,0-3,3v5.813a3.007,3.007,0,0,0,3,3h5.813a3.007,3.007,0,0,0,3-3V104a3.007,3.007,0,0,0-3-3Z"
                                                                transform="translate(-1069.932 -99.628)"
                                                                fill="#fff"
                                                            />
                                                        </g>
                                                    </svg>
                                                </a>
                                            </li>
                                        }
                                        {
                                            data?.tiktok && <li>
                                                <a
                                                    href={data?.tiktok}
                                                    target={'_blank'}
                                                    rel="noreferrer"
                                                >
                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z"
                                                            stroke="white"/>
                                                        <path
                                                            d="M20.3671 12.0455C19.7369 11.9067 19.1662 11.5641 18.7383 11.0679C18.3103 10.5716 18.0475 9.94752 17.9881 9.28661V9H15.8142V17.8856C15.8128 18.279 15.6918 18.6621 15.4682 18.981C15.2446 19.2999 14.9295 19.5386 14.5674 19.6636C14.2052 19.7886 13.8141 19.7936 13.4491 19.6779C13.084 19.5622 12.7634 19.3315 12.5321 19.0184C12.2973 18.7001 12.1671 18.313 12.1603 17.9133C12.1534 17.5135 12.2704 17.122 12.4943 16.7953C12.7181 16.4687 13.0371 16.224 13.4051 16.0967C13.7731 15.9694 14.1708 15.9661 14.5407 16.0873V13.8126C13.7159 13.6952 12.876 13.8462 12.1386 14.2446C11.4012 14.643 10.8031 15.2688 10.4281 16.0345C10.053 16.8002 9.9197 17.6675 10.0468 18.515C10.1739 19.3626 10.555 20.148 11.1369 20.7615C11.6918 21.3463 12.4033 21.7475 13.1806 21.9139C13.9579 22.0803 14.7657 22.0044 15.5009 21.6958C16.2362 21.3873 16.8654 20.8601 17.3084 20.1816C17.7514 19.503 17.988 18.7039 17.9881 17.8863V13.346C18.8666 13.9929 19.92 14.3397 21 14.3378V12.1131C20.7873 12.1134 20.5752 12.0907 20.3671 12.0455Z"
                                                            fill="white"/>
                                                    </svg>


                                                </a>
                                            </li>
                                        }

                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Accordion>
                <div className="footer__bottom">
                    <Container>
                        <Row>
                            <Col md={3} className={'one'}>
                                <p>© {year} Elite Tinting. All Rights Reserved.</p>
                            </Col>
                            <Col md={3} className={'two'}>
                                <p>
                                    <a target={'blank'} href={'https://finnkits.com'}>
                                        Design & Developed by Finnkits
                                    </a>
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Footer>
        </>
    )
}

const Footer = styled.section`
    background: #3c3c3b;
    position: relative;
    overflow: hidden;

    .content_notices {
        p {
            margin: 0;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.5);
        }
    }

    .footer-address {
        padding-right: 30px;
    }

    .open-hours-data {
        color: #fff;
    }

    .open-hours-container {
        line-height: 1.5; /* Add spacing between lines */
    }

    .open-hours-item {
        margin-bottom: 15px; /* Space between items */
    }

    .day {
        display: block;
        font-weight: bold; /* Bold for days */
        margin-bottom: 5px; /* Space between day and time */
    }

    .time {
        color: #ccc; /* Lighter color for time */
    }

    h1 {
        color: white;
    }

    .footer {
        &__top {
            .container {
                position: relative;
                padding: 80px 15px 40px;

                &:before {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    content: '';
                    width: calc(100% - 30px);
                    margin: 0 auto;
                    height: 1px;
                    background: rgba(247, 243, 240, 0.3);
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }

            &__info {
                margin-top: 40px;

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 24px;
                    margin-bottom: 40px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    span {
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 0.5);
                        line-height: 24px;
                        margin-bottom: 10px;
                    }

                    a {
                        font-size: 16px;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 24px;
                    }
                }
            }

            .border {
                border: none !important;
            }

            &__menu {
                p {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    color: rgba(255, 255, 255, 0.5);
                    margin-bottom: 20px;
                }

                ul {
                    li {
                        margin-bottom: 15px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            display: inline-flex;
                            align-items: flex-start;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            color: #ffffff;
                        }
                    }
                }
            }

            .social {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                svg {
                    overflow: hidden;
                    border-radius: 50%;

                    #Ellipse_603,
                    path {
                        transition: 1s all cubic-bezier(0.25, 0.74, 0.22, 0.99);
                    }
                }

                li {
                    margin-left: 0px;
                    margin-bottom: 20px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        &__bottom {
            padding: 40px 0;
            //background: #5C5550;

            p {
                color: rgba(255, 255, 255, 0.5);
                font-size: 12px;
                line-height: 24px;
                font-weight: 500;
            }

            a {
                color: rgba(255, 255, 255, 0.5);
                font-size: 12px;
                line-height: 24px;
                font-weight: 500;
            }
        }
    }

    //responsive

    .hide-mobile {
        display: none;
    }

    .accordion-item {
        .accordion-header {
            margin: 0;
            padding: 0;
            border: none;
            width: 100%;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(198, 198, 198, 0.5);

            button {
                margin: 0;
                padding: 0;
                border: none;
                background: transparent;
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-size: 20px;
                font-weigt: 500;
                line-height: 28px;
                color: rgba(255, 255, 255, 0.5);

                span {
                    svg {
                        transition: 1s all cubic-bezier(0.25, 0.74, 0.22, 0.99);
                    }
                }
            }

            button[aria-expanded='true'] {
                color: rgba(255, 255, 255, 1);

                span {
                    svg {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .accordion-body {
            padding-top: 20px;
        }
    }

    @media (min-width: 1550px) {
        .fixed-bg-1 {
            top: 100px;
        }

        .footer {
            &__top {
                h2 {
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 30px;
                    color: #222222;
                    text-transform: uppercase;
                    margin: 0 0 20px;
                    width: 80%;

                    br {
                        display: none;
                    }
                }

                &__info {
                    ul {
                        li {
                            a {
                                font-size: 18px;
                            }

                            p {
                                font-size: 18px;
                            }
                        }
                    }
                }

                &__menu {
                    ul {
                        li {
                            a {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 992px) and (min-width: 768px) {
        #Logo_-_White {
            height: 30px !important;
            width: auto !important;
        }
    }
    @media (min-width: 768px) {
        .footer__bottom {
            position: relative;

            &:after {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 1px;
                width: calc(100% - 60px);
                margin: 0 auto;
            }
        }
    }
    @media (max-width: 767px) {
        .hide-desktop {
            display: none !important;
        }

        .hide-mobile {
            display: block !important;
        }

        .nomargin {
            margin-bottom: 0 !important;
        }

        .footer__bottom {
            padding-top: 20px !important;
        }

        .content_notices {
            margin-top: 80px;
            margin-bottom: 20px;
        }

        .footer {
            &__top {
                .container {
                    padding: 0px 15px 0px;
                }

                .social {
                    align-items: flex-start;
                    flex-direction: row;
                    //padding-bottom: 40px;
                    padding-bottom: 0px;
                    //border-bottom: 1px solid rgba(247, 243, 240, 0.3);
                    margin-top: 40px;

                    li {
                        margin-bottom: 0;
                        margin-right: 20px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .footer__top_col {
                    margin-bottom: 40px;
                    border: none !important;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &.border {
                        .footer__top__menu {
                            padding-bottom: 0px;
                            //border-bottom: 1px solid rgba(247, 243, 240, 0.3) !important;
                            border-top: none !important;
                            border-left: none !important;
                            border-right: none !important;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                h2 {
                    br {
                        display: none;
                    }
                }

                &__info {
                    margin-bottom: 80px;
                }

                &__menu {
                }
            }

            &__bottom {
                svg {
                    margin-bottom: 40px;
                }

                .text-right {
                    margin-top: 10px;
                    text-align: left !important;
                }

                .three {
                    order: 1;
                    margin-bottom: 40px;
                }

                .one {
                    order: 3;
                }

                .two {
                    order: 4;
                }

                .four {
                    order: 2;
                    margin-bottom: 40px;
                }
            }
        }

        .footer-top-bar {
            .flex {
                flex-direction: column;
                align-items: flex-start;

                .text {
                    margin: 0 0 10px;
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
    }
`

export default React.memo(MyComponent)
