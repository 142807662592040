import React from 'react'
import { Container } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components'
import { title } from '../styles/globalStyleVars'

const CallToAction = ({ title, desc, leftAlign, padding }) => {
  return (
    <StyledCallToAction padding={padding} leftAlign={leftAlign}>
      <Container>
        {title && <h2>{title}</h2>}
        {desc && <p>{ReactHtmlParser(desc)}</p>}
      </Container>
    </StyledCallToAction>
  )
}

const StyledCallToAction = styled.section`
  height: 100%;

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  }

  h2 {
    font-size: 48px;
    font-family: ${title};
    text-transform: uppercase;
    padding-top: ${props => (props.padding ? 30 : 0)}px;
    margin: ${props => (props.leftAlign ? '0 auto' : 0)};
  }

  p {
    margin-top: 30px;
    margin: ${props => (props.leftAlign ? '0 auto' : 0)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    margin-top: 20px;
    h2 {
      font-size: 30px;
    }
  }
`

export default CallToAction
