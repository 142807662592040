export const apiEndPoints = {
  PAGE_DATA: "get-req-data/page-data",
  SECTIONS: "get-req-data/sections",
  CHILD_PAGE: "get-req-data/child-pages",
  MEDIA: "get-req-data/media-by-cats",
  PROJECTS: "get-req-data/product-by-cats",
  FORM_SUBMIT: "post-req-data/form-submit",
  POSTS: "get-req-data/posts",
  PROJECTS_DATA: "get-req-data/product-data",
  BLOG_LIST: "get-req-data/blog-list",
  PAGE_POST: "get-req-data/page-post-data",
  GLOBAL_SETTING: "get-req-data/settings-data",
};
