import {configureStore} from "@reduxjs/toolkit";
import globalReducer from '../redux/global'
import contactReducer from '../redux/contact'
import homeReducer from '../redux/home'
import elitetintReducer from '../redux/elitetint'
import accessoriesReducer from '../redux/autoAccessories'
import galleryReducer from '../redux/gallery'
import footerReducer from '../redux/footer'
import priceListReducer from "../redux/priceList";
import generalReducer from "../redux/general";
import disclaimerReducer from "../redux/disclaimer";


const store = configureStore({
    reducer: {
        globalReducer,
        home: homeReducer,
        eliteting : elitetintReducer,
        autoAccessories : accessoriesReducer,
        gallery : galleryReducer,
        priceList : priceListReducer,
        disclaimer : disclaimerReducer,
        general : generalReducer,
        contact: contactReducer,
        footer: footerReducer
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

