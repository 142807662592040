import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { hover } from '../../styles/globalStyleVars'
import CallToAction from '../CallToAction'
import ReactHtmlParser from "react-html-parser";

const Info = ({data}) => {
  return (
    <StyledInfo>
      <Container>
        <Row>
          <Col lg={8} md={12}>
            <CallToAction
              title={data?.section_data?.subtitle}
              desc={data?.section_data?.short_desc}
            />
          </Col>
          <Col lg={4} md={12}>
            <div className="hours-container">
              <h2>Hours of Operation</h2>
              {ReactHtmlParser(data?.section_data?.description)}
            </div>
          </Col>
        </Row>
      </Container>
    </StyledInfo>
  )
}

const StyledInfo = styled.section`
  padding: 120px 0px;
  background-color: #3c3c3b;
  color: #fff;

  p {
    color: #fff;
  }

  .hours-container {
    background: ${hover}; /* Dark background with opacity */
    color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    @media (min-width: 768px) and (max-width: 991px) {
      margin-top: 60px;
    }
    @media (max-width: 767px) {
      margin-top: 60px;
    }
  }

  .hours-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  .hours {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .day {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
  }

  .day span {
    display: block;
  }
`
export default Info
