import { useEffect } from 'react'
import ErrorPage from '../components/error/404'

const Home = () => {
  useEffect(() => {}, [])

  return (
    <>
      <ErrorPage />
    </>
  )
}

export default Home
