import {motion} from 'framer-motion'
import React, {useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {apiEndPoints} from '../../api/network/apiEndPoints'
import {ApiParam} from '../../api/network/apiParams'
import Accordion from '../../components/Accordion'
import {PageAnimation} from '../../components/animations/PageAnimation'
import Note from '../../components/contact/Note'
import Benefits from '../../components/eliteTinting/Benefits'
import GetInTouchElite from '../../components/eliteTinting/GetInTouchElite'
import InnerBanner from '../../components/InnerBanner'
import WhoWeAre from '../../components/WhoWeAre'
import ZigZag from '../../components/ZigZag'
import {fetchElite} from "../../api/redux/elitetint";

const EliteTinting = ({offset}) => {

    const dispatch = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: 'elite-tinting',
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
        }

        dispatch(fetchElite([api_url, api_params]))
    }, [])

    const getData = useSelector((state) => state.eliteting);


    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "elite-tint-banner");
    const about = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "elite-about");
    const benefit = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "elite-benefit");
    const residentialData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "residential");
    const marineData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "marine");
    const accordionData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "accordion");
    const formData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "tinting-form");
    const noteData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "note");

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>About | Elite Tint</title>
                <meta
                    name="description"
                    content="Learn more about Elite Tint, your trusted provider for professional window tinting services for automotive, residential, commercial, and marine applications. Discover our commitment to quality, customer satisfaction, and expert installation."
                />
            </Helmet>

            <motion.div
                key={`1w83`}
                className="page-loader"
                exit="exit"
                animate="anim"
                variants={PageAnimation}
                initial="init"
            ></motion.div>

            <StyledComponent>
                <InnerBanner
                    data={banner}
                    img={banner?.images?.list?.[0]?.full_path}
                    srcSm={banner?.images?.list?.[0]?.full_path}
                />
                <WhoWeAre
                    id={'automotive'}
                    title={about?.section_data?.subtitle}
                    desc={about?.section_data?.description}
                    img={about?.images?.list?.[0]?.full_path}
                />
                <Benefits data={benefit} />
                <ZigZag
                    id={'residential-commercial'}
                    marineId={'marine'}
                    offset={offset}
                    residentialData={residentialData}
                    marineData={marineData}
                />
                <Accordion data={accordionData} />
                <GetInTouchElite data={formData} />
                <Note
                    title={'Note'}
                    desc={
                        noteData?.section_data?.description
                    }
                />
            </StyledComponent>
        </HelmetProvider>
    )
}

const StyledComponent = styled.section``

export default EliteTinting