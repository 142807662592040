import React from 'react'
import styled from 'styled-components'
import ReactHtmlParser from "react-html-parser";

const TableList = ({items}) => {
  return (
    <StyledTableList>
      <div className="table-container">
        <h2>{items?.data?.title}</h2>
        {ReactHtmlParser(items?.data?.description)}
      </div>
    </StyledTableList>
  )
}

const StyledTableList = styled.section`
  margin-top: 40px;
  margin-bottom: 40px;

  h2 {
    font-size: 28px;
    text-align: center;
    padding-bottom: 30px;
  }

  .table-container {
    width: 80%;
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .pricing-table {
    width: 100%;
    border-collapse: collapse;
  }

  .pricing-table thead {
    background-color: #ddd;
  }

  .pricing-table th,
  .pricing-table td {
    padding: 12px 16px;
    text-align: left;
  }

  .pricing-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .pricing-table tbody tr:hover {
    background-color: #e9e9e9;
  }

  .pricing-table th {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
  }

  .pricing-table td {
    font-size: 14px;
    color: #333;
  }

  .pricing-table td:last-child {
    text-align: right;
  }
`

export default TableList
