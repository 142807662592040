import React, {useEffect} from 'react'
import {Col, Container, Form, Row} from 'react-bootstrap'
import styled from 'styled-components'
import {red} from '../../styles/globalStyleVars'
import Button from '../ButtonSubmit'
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/contact";

const GetInTouch = ({data}) => {
    const dispatch = useDispatch();

    const responseData = useSelector((state) => state.contact);
    const {register, handleSubmit, formState, reset} = useForm({mode: "all"});


    //--- form submit
    const success = (msg) =>
        toast.success(msg, {
            position: "top-right",
            autoClose: 4000,
            closeOnClick: true,
            progress: undefined,
        });

    const error = (msg) =>
        toast.error(msg, {
            position: "top-right",
            autoClose: 4000,
            closeOnClick: true,
            progress: undefined,
        });

    const onSubmit = async (e) => {
        let api_services = apiEndPoints?.FORM_SUBMIT;

        var formData = new FormData();
        formData.append("fName", e?.fName);
        formData.append("lName", e?.lName);
        formData.append("phone", e?.phone);
        formData.append("email", e?.email);
        formData.append("service", e?.service);
        formData.append("year", e?.year);
        formData.append("make", e?.make);
        formData.append("message", e?.message);
        formData.append("form_id", "contact-form");

        if (e.fName !== "" && e.lName !== "" && e.phone !== "" && e.email !== "") {
            document.body.style.overflow = 'hidden';
            await dispatch(postForm([api_services, formData]));
            document.body.style.overflow = 'auto';
            reset();
        }
    };

    let count = 0;

    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error("please fill out the correct inputs");
        }
        count = 0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== "") {
            error(responseData?.error);
        }
        if (responseData && responseData?.success !== "") {
            success(responseData?.success);
        }
    }, [responseData]);


    return (
        <StyledComponent>
            <div className="form-area">
                <div className="form-area__form">
                    <Container>
                        <Row>
                            <Col sm={{span: 10, offset: 1}} className={'text-center'}>
                                <div className="form-area__form__title">
                                    <h2 className={'split-up'}>{data?.section_data?.subtitle}</h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={{span: 10, offset: 1}}>
                                <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>
                                    <Row>
                                        <input name={'spam_protector'} type="hidden"/>
                                        <Col className={'form__item'} md={6} xs={12}>
                                            <Form.Label>First Name*</Form.Label>
                                            <Form.Control
                                                className={
                                                    formState?.errors?.fName?.message ? 'has-error form-control-lg'
                                                        : 'form-control-lg'
                                                }
                                                {...register('fName', {
                                                    required: 'First Name is required',
                                                })}
                                                type="text"
                                                placeholder="Enter your first name*"
                                            />
                                        </Col>
                                        <Col className={'form__item'} md={6} xs={12}>
                                            <Form.Label>Last Name*</Form.Label>
                                            <Form.Control
                                                className={
                                                    formState?.errors?.lName?.message
                                                        ? 'has-error form-control-lg'
                                                        : 'form-control-lg'
                                                }
                                                {...register('lName', {
                                                    required: 'Last Name is required',
                                                })}
                                                type="text"
                                                placeholder="Enter your last name*"
                                            />
                                        </Col>
                                        <Col className={'form__item'} md={6} xs={12}>
                                            <Form.Label>Phone Number*</Form.Label>
                                            <Form.Control
                                                className={
                                                    formState?.errors?.phone?.message
                                                        ? 'has-error form-control-lg'
                                                        : 'form-control-lg'
                                                }
                                                {...register('phone', {
                                                    required: {
                                                        value: true,
                                                        message: 'please enter your phone number',
                                                    },
                                                    pattern: {
                                                        message:
                                                            'please enter a valid 11 digit phone number',
                                                    },
                                                })}
                                                type="text"
                                                placeholder="Enter your phone number*"
                                            />
                                        </Col>
                                        <Col className={'form__item'} md={6} xs={12}>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                {...register('email', {
                                                    pattern: {
                                                        message: 'please enter a valid email address',
                                                    },
                                                })}
                                                type="email"
                                                placeholder="Enter your email address"
                                            />
                                        </Col>
                                        <Col className={'form__item'} sm={12}>
                                            <Form.Label>Service Interested In</Form.Label>
                                            <Form.Control
                                                {...register('service', {
                                                    pattern: {
                                                        message: 'please enter services interested in.',
                                                    },
                                                })}
                                                type="text"
                                                placeholder="Enter your service interested in."
                                            />
                                        </Col>
                                        <Col className={'form__item'} sm={4}>
                                            <Form.Label>Year</Form.Label>
                                            <Form.Control
                                                {...register('year', {
                                                    pattern: {
                                                        message: 'please enter year',
                                                    },
                                                })}
                                                type="text"
                                                placeholder="Enter your year"
                                            />
                                        </Col>
                                        <Col className={'form__item'} sm={4}>
                                            <Form.Label>Make</Form.Label>
                                            <Form.Control
                                                {...register('make', {
                                                    pattern: {
                                                        message: 'please enter make',
                                                    },
                                                })}
                                                type="text"
                                                placeholder="Enter your make"
                                            />
                                        </Col>
                                        <Col className={'form__item'} sm={4}>
                                            <Form.Label>Model</Form.Label>
                                            <Form.Control
                                                {...register('model', {
                                                    pattern: {
                                                        message: 'please enter model',
                                                    },
                                                })}
                                                type="text"
                                                placeholder="Enter your model"
                                            />
                                        </Col>

                                        <Col md={12}>
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={6} // This sets the number of visible rows, adjust as needed
                                                style={{height: '180px'}} // Custom style for the height
                                                className={`${
                                                    formState?.errors?.message?.message
                                                        ? 'has-error form-control-lg'
                                                        : 'form-control-lg '
                                                } cover-letter`}
                                                {...register('message')}
                                                placeholder="Message"
                                            />
                                        </Col>

                                        <Col className={'form__item'} md={6} xs={12}>
                                            <div onClick={handleSubmit(onSubmit, onError)} className="button">
                                                <Button
                                                    width={'100%'}
                                                    fontSize={'16'}
                                                    text={'Submit Message'}
                                                    color={'#FFFFFF'}
                                                    background={red}
                                                    hoverBackground={'#3C3C3B'}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </StyledComponent>
    )
}

const StyledComponent = styled.section`
    position: relative;

    .select-here .custom__control {
        margin: 0;
    }

    textarea,
    .form-control {
        height: auto;
        background: transparent;
        border-radius: 20px;
        padding: 20px;
        color: #1d3130;
        border-color: #1d3130;

        &::placeholder {
            color: rgba(29, 49, 48, 0.5);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        &:focus {
            border: 1px solid #1d3130;
        }
    }

    .form-area {
        &__form {
            h1 {
                color: #1d3130;
            }
        }
    }

    .form-area input.cover-letter.form-control {
        padding-top: unset;
    }

    .custom__control {
        background-color: transparent;
        border: 1px solid #1d3130 !important;
        box-shadow: none;
        outline: none !important;
        cursor: pointer;
        //margin-bottom: 60px;
        margin-bottom: 20px;
        border-radius: 50px !important;
        height: 60px;
        padding: 0 24px 0 30px;

        .custom__indicator-separator {
            display: none;
        }

        .custom__value-container {
            padding: 0 !important;
            //height: 60px;
        }

        .css-qbdosj-Input {
            margin: 0;
            padding: 0;
        }

        .custom__placeholder,
        .custom__single-value {
            top: 42% !important;
        }

        .custom__indicator {
            top: -3px;
        }

        svg line {
            stroke: #1d3130;
        }

        .custom__single-value {
            color: #1d3130;
            font-size: 15px;
            line-height: 20px;
        }

        .custom__placeholder {
            color: #1d3130;
            font-size: 16px;
            line-height: 24px;
        }

        .custom__value-container {
            padding-left: 0;
        }

        &--is-focused {
        }
    }
`

export default GetInTouch
