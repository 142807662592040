import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import styled from 'styled-components'
import {Img} from '../Img'

const AccessoriesGallery = ({data}) => {
    const services = data?.posts?.list;
    return (
        <StyledComponent className={'accessories-gallery'}>
            <Container>
                {data?.section_data?.subtitle && <Row>
                    <Col>
                        <h1>{data?.section_data?.subtitle}</h1>
                    </Col>
                </Row>}

                <Row>
                    <Col sm={12} className={'accessories-gallery__title'} sm={12}>
                        <h1>{data?.section_data?.short_desc}</h1>
                    </Col>
                    {
                        services && services?.length > 0 && services?.map((item) => {
                            return (
                                <Col lg={4} md={6} className={'accessories-gallery__single'}>
                                    <div className="accessories-gallery__single__img">
                                        <Img src={item?.images?.[0]?.full_path}/>
                                    </div>
                                    <h2>{item?.data?.subtitle}</h2>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    )
}

const StyledComponent = styled.section`
    padding: 120px 0;
    overflow: hidden;

    .accessories-gallery {
        &__title {
            h1 {
                font-size: 48px;
                font-weight: 400;
                margin: 40px 0px;
                text-align: center;
            }
        }

        &__single {
            position: relative;
            overflow: hidden;
            margin-bottom: 30px;

            &__img {
                position: relative;
                overflow: hidden;
                padding-top: calc(300 / 228 * 100%);

                a {
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    top: 0;
                }
            }

            h2 {
                position: absolute;
                content: '';
                inset: 0;
                font-size: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
            }
        }
    }

    @media (max-width: 767px) {
        padding: 60px 0;
        h1 {
            font-size: 30px;
        }
    }
`

export default AccessoriesGallery
