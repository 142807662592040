import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import styled from 'styled-components'
import Title from '../Title'

const Benefits = ({data}) => {
    return (
        <StyledComponent className={'benefits-area'}>
            <Container>
                {data?.section_data?.title && <Row>
                    <Col className={'text-center'}>
                        <Title margin={'0 0 60px 0'} text={data?.section_data?.title}/>
                    </Col>
                </Row>}

                <Row className={'benefits-area-items'}>
                    {
                        data?.images?.list?.map((item) => {
                            return (
                                <Col className={'benefits-item text-center'} sm={3}>
                                    <img src={item?.full_path} alt="increase"/>
                                    <h4>{item?.short_title}</h4>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    )
}

const StyledComponent = styled.section`
    padding: 60px 0;
    background-color: aliceblue;

    .benefits-area-items {
        padding: 30px;
    }

    .benefits-item {
        text-align: center;
        margin-bottom: 40px;

        img {
            margin-bottom: 20px;
        }
    }
`

export default Benefits
